@import 'theme.scss';

.root {
  align-items: center;
  background: $color-white;
  border: 1px solid $color-gray;
  border-radius: 0.5rem;
  box-shadow: 0 0 8px 0 rgba(139, 139, 139, 0.5);
  color: unset;
  display: flex;
  flex-direction: column;
  font-size: $text-xs;
  line-height: $line-height-xs;
  margin: $space-xs $space-xxs;
  min-width: 8rem;
  overflow: hidden;
  padding: $space-xs $space-xxs;
  text-align: center;
  text-decoration: none;

  &:hover {
    border-color: var(--link-color--hover);
    color: $color-black;
    cursor: pointer;
  }
}

.link {
  text-decoration: none;
}

.details {
  color: $color-black;
}

.active {
  border: 1px solid var(--link-color);
  box-sizing: border-box;
  color: $color-black;
}

.recommended {
  background-color: var(--brand-success);
  color: $color-white;
  font-size: 0.675rem;
  font-weight: $font-weight-bold;
  margin-bottom: $space-xs;
  margin-top: -$space-xs;
  margin-left: -$space-xs;
  margin-right: -$space-xs;
  padding: $space-xxs $space-sm;
  text-transform: uppercase;
  width: max-content;

  svg {
    margin-right: 0.25rem;
  }
}

.headshot {
  font-size: $text-lg;
  margin-bottom: $space-xxs;
  position: relative;
  z-index: 1;
}

.headshot__saved {
  background: var(--color-primary);
  border: 3px solid $color-white;
  border-radius: 50%;
  color: $color-white;
  font-size: $text-xs;
  height: 26px;
  left: 72%;
  position: absolute;
  width: 26px;
  z-index: 1;
}

.saved__rank {
  align-items: center;
  bottom: 0;
  color: white;
  display: flex;
  font-size: $text-xs;
  height: 20px;
  left: 0;
  margin: auto;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
}

// TO MAKE HEADSHOT ONLY CANDIDACY LIST ITEMS
.small {
  position: relative;
  .details,
  .recommended {
    display: none;
  }

  a {
    padding: $space-xxs 0.25rem;
    border-radius: 50%;
    &:focus {
      outline: none;
    }
  }

  .active {
    box-shadow: 0 0 0 3px var(--link-color);
  }

  .root {
    border: 3px solid transparent;

    border-radius: 50%;
    min-width: unset;
    width: 66px;
    height: 66px;
    padding: 0;

    &:focus {
      outline: none;
    }

    .headshot {
      position: unset;
    }
  }
}

.withdrawn {
  img {
    opacity: 0.5;
  }

  color: $color-gray-dark;

  &:hover {
    cursor: default;
  }
}

@media (min-width: $breakpoint-md) {
  .CandidacyListItem {
    margin: $margin-compact-horizontal-only;
    min-width: 8rem;
  }
}

@media (min-width: $breakpoint-lg) {
  .CandidacyListItem {
    margin: $margin-cozy-horizontal-only;
  }
}
