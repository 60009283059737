/* ---------------- TYPOGRAPHY ------------ */

/**
  * @tokens Font Size
  * @presenter FontSize
  */

$text-xs: 0.875rem;
$text-sm: 1rem;
$text-md: 1.125rem;
$text-lg: 1.25rem;
$text-xl: 1.5rem;

$h1-font-size: $text-xl; // 24px
$h1-font-size-mobile: $text-lg;

$h2-font-size: $text-md; // 18px;
$h3-font-size: $text-sm; // 16 px

$body-font-size: $text-sm; // 16px

/**
  * @tokens Line Height
  * @presenter LineHeight
  */

$line-height-xs: 1.25;
$line-height-sm: 1.375;
$line-height-md: 1.5;
$line-height-xl: 1.875;

$h1-line-height: $line-height-xl; // 30px;
$h2-line-height: $line-height-sm; // 22px;
$h3-line-height: $line-height-xs; // 20px
$body-line-height: $line-height-md; // 24px

@mixin h1styles {
  font-size: $h1-font-size-mobile;
  font-weight: $font-weight-bold;
  line-height: $line-height-md;

  @media (min-width: $breakpoint-sm) {
    font-size: $h1-font-size;
    line-height: $h1-line-height;
  }
}

@mixin h3titlestyles {
  font-family: var(--font-family-headings) !important;
  font-weight: $font-weight-bold !important;
  font-size: $h2-font-size !important;
  line-height: $line-height-sm !important;
  text-transform: uppercase;
}

/**
  * @tokens Font Weight
  * @presenter FontWeight
  */

$font-weight-regular: 400;
$font-weight-bold: 600;
$font-weight-extra-bold: 700;

$body-font-weight: $font-weight-regular;

/* --------------- SPACING ---------------- */

/* margins */

/**
  * @tokens Spacing
  * @presenter Spacing
  */

$space-xxxs: 0.25rem; // use sparingly
$space-xxs: 0.5rem;
$space-xs: 1rem;
$space-sm: 2.5rem;
$space-md: 5rem;
$space-lg: 10rem;
$space-xl: 20rem;

/**
  * @tokens Other Space Tokens
  */

$p0: 0;
$padding-compact: $space-xxs;
$padding-compact-rectangle: $space-xxs $space-xs;
$padding-cozy: $space-xs;
$padding-comfortable: $space-sm;

$m0: 0;
$margin-compact: $space-xxs;
$margin-compact-vertical-only: $space-xxs 0;
$margin-compact-horizontal-only: 0 $space-xxs;

$margin-cozy: $space-xs;
$margin-cozy-vertical-only: $space-xs 0;
$margin-cozy-horizontal-only: 0 $space-xs;

$margin-comfortable: $space-sm;
$margin-comfortable-vertical-only: $space-sm 0;
$margin-comfortable-horizontal-only: 0 $space-sm;

/* ------------------- BREAKPOINTS ---------- */

/**
  * @tokens Breakpoints
  */
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1150px;
$breakpoint-xxl: 1400px;
