@import 'theme.scss';

$button-line-height: $body-line-height;
$button-padding: $padding-compact-rectangle;

.Button {
  border: 1px solid transparent;
  border-radius: 10rem;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: $text-sm;
  font-weight: $font-weight-regular;
  line-height: $button-line-height;
  padding: $button-padding;
  text-align: center;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    opacity 0.15s ease-in-out;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &:active,
  &:hover {
    opacity: 0.8;
    text-decoration: none;
  }

  // focus styles based on https://www.sarasoueidan.com/blog/focus-indicators/
  &:focus {
    outline: 2px solid var(--link-color);
    outline-offset: 2px;
    text-decoration: none;
  }

  :focus:not(:focus-visible) {
    outline: unset;
    text-decoration: unset;
  }

  button:focus-visible {
    outline: 2px solid var(--link-color);
    outline-offset: 2px;
    text-decoration: none;
  }

  // remove default link styling UNLESS link variant is specified
  a,
  a:visited {
    color: inherit;
    text-decoration: none;
  }
}

.Button__inner {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
}

.ButtonSpinner {
  border-color: #ffc3c2;
  border-top-color: var(--brand-danger);
  border-width: 0.25rem;
  height: 2rem;
  left: calc(50% - $space-sm);
  position: absolute;
  top: calc(50% - $space-sm);
  width: 2rem;
}

/* Sizes */
.Button__lg {
  font-size: $text-lg;
  line-height: $button-line-height;
  padding: $button-padding;
}

.Button__sm {
  font-size: $text-xs;
  line-height: $button-line-height;
  padding: $space-xxs;
}

/* Block level */
.Button__block {
  display: block;
  width: 100%;
}

// padding
.padded {
  padding: $space-xxs $space-md;
}

.uppercase {
  font-weight: $font-weight-bold;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

// square
.square {
  border-radius: 0.25rem;
}

.bold {
  font-weight: $font-weight-extra-bold;
}

/* Types */
.Button__black {
  background: $color-black;
  border-color: $color-black;
  color: $color-white;
}

.Button__link {
  background: transparent;
  border: none;
  color: var(--link-color);
  min-height: 2rem;
  padding: $p0;
}

.Button__danger,
a.Button__danger {
  background-color: var(--brand-danger);
  border-color: var(--brand-danger);
  color: $color-white;
}

.Button__danger:active,
.Button__danger:hover {
  background-color: var(--brand-danger);
  border-color: var(--brand-danger);
  color: $color-white;
}

.Button__default,
a.Button__default {
  background-color: $color-gray-dark;
  border-color: $color-gray-dark;
  color: $color-white;
}

.Button__default:active,
.Button__default:hover {
  background-color: $color-gray-dark;
  border-color: $color-gray-dark;
  color: $color-white;
}

.Button__primary,
a.Button__primary {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: $color-white;

  &:active,
  &:hover {
    background: var(--color-primary-darkened);
    border-color: var(--color-primary-darkened);
    color: $color-white;
  }
}

.Button__secondary,
a.Button__secondary {
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
  color: $color-white;
}

.Button__secondary:active,
.Button__secondary:hover {
  background: var(--color-secondary);
  border-color: var(--color-secondary);
  color: $color-white;
}

.Button__success {
  background-color: var(--brand-success);
  border-color: var(--brand-success);
  color: $color-white;
}

.Button__success:active,
.Button__success:hover {
  background: var(--brand-success);
  border-color: var(--brand-success);
  color: $color-white;
}

.Button__white {
  background: $color-white;
  border-color: $color-white;
  color: $color-black;
}

.Button__link {
  background: transparent;
  color: var(--link-color);
  margin: 0 auto;
  text-decoration: underline;
}

a.Button__panel,
.Button__panel {
  background: $color-white;
  border-radius: 0;
  box-shadow: 0 0 8px 0 $color-gray;
  color: $color-black;
  font-weight: $font-weight-bold;
  line-height: $button-line-height;
  margin: $margin-cozy-horizontal-only;
  padding: $padding-cozy;
  text-align: left;
  white-space: normal;
}

.Button__panel:active,
.Button__panel:hover {
  box-shadow: 0 0 8px 0 $color-gray-dark;
}

/* Disabled */
.Button__disabled {
  background-color: $brand-disabled;
  border-color: $brand-disabled;
  cursor: not-allowed;
  pointer-events: none;
}

/* Outline */
.Button__outline,
a.Button__outline {
  background-color: transparent;
}

.Button__outline.Button--danger,
a.Button__outline.Button--danger {
  color: var(--brand-danger);
}

.Button__outline.Button--danger:active,
.Button__outline.Button--danger:hover,
a.Button__outline.Button--danger:hover {
  color: $color-white;
}

.Button__outline.Button__default,
a.Button__outline.Button__default {
  color: $color-gray-dark;
}

.Button__outline.Button__default:active,
.Button__outline.Button__default:hover,
a.Button__outline.Button__default:hover {
  color: $color-white;
}

.Button__outline.Button__primary,
a.Button__outline.Button__primary {
  color: getColor(var(--color-primary), $color-primary);
}

.Button__outline.Button__primary:active,
.Button__outline.Button__primary:hover,
a.Button__outline.Button__primary:hover {
  color: $color-white;
}

.Button__outline.Button--secondary,
a.Button__outline.Button--secondary {
  color: var(--color-secondary);
}

.Button__outline.Button--secondary:active,
.Button__outline.Button--secondary:hover,
a.Button__outline.Button--secondary:hover {
  color: $color-white;
}

.Button__outline.Button--success,
a.Button__outline.Button--success {
  color: var(--brand-success);
}

.Button__outline.Button--success:active,
.Button__outline.Button--success:hover,
a.Button__outline.Button--success:hover {
  color: $color-white;
}

.Button__outline.Button__disabled,
a.Button__outline.Button__disabled {
  color: $brand-disabled;
}

.Button__outline.Button__white,
a.Button__outline.Button__white {
  color: $color-white;
}

.Button__with__icon svg {
  margin-right: $space-xxs;
}

.Button--panel.Button__with__icon svg {
  font-size: 2rem;
  margin-right: $space-xs;
}

.Button__with__icon__only svg {
  margin-right: $m0;
}
